



































import { promiseHelper } from '@/helper/promise-helper'
import { Observer } from 'mobx-vue'
import { Component, Inject, Prop, Vue } from 'vue-property-decorator'
import { postController } from './post-controller'

@Observer
@Component({
  components: {},
})
export default class extends Vue {
  postController = postController
}
